import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = () => {
  const { site } = useStaticQuery(query)

  const {
    siteTitle,
    siteUrl,
    siteDescription,
    siteKeywords
  } = site.siteMetadata

  return (
    <Helmet title={siteTitle}>
      <meta name="description" content={siteDescription} />

      {siteUrl && <meta property="og:url" content={siteUrl} />}

      {siteTitle && <meta property="og:title" content={siteTitle} />}

      {siteDescription && (
        <meta property="og:description" content={siteDescription} />
      )}

      {siteDescription && (
        <meta property="keywords" content={siteKeywords} />
      )}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteTitle
        siteUrl
        siteDescription
        siteKeywords
      }
    }
  }
`
